<template>
  <div>
    <van-nav-bar title="添加修理厂" left-arrow :fixed="true" :z-index="999" @click-left="back" />

    <div style="padding-top:52px;">
      <div style="position: relative;  height: 45px;">
        <van-search v-model.trim="keyword" placeholder="请输入相关关键词" @focus="put" />
        <div v-show="keyword" class="shikuan" v-if="show">
          <ul>
            <li
              v-for="item in cityList"
              :key="item.id"
              @click="handleCityClick(item)"
              class="linam"
            >{{item.name}}</li>
          </ul>
        </div>
      </div>
      <van-row v-if="list">
        <van-col span="20" class="ls">{{list.name}}</van-col>
        <van-col span="4" class="rs" @click="detparts()">删除</van-col>
      </van-row>
    </div>
    <div class="sumit">
      <van-button class="tijiao" type="danger" size="normal" @click="submitForm">添加</van-button>
    </div>
    <van-dialog
      v-model="shenhe"
      title="提 示"
      show-cancel-button
      confirm-button-text="确定提交"
      cancel-button-text="取消"
      @confirm="queding"
      @cancel="quxiao"
    >
      <div class="tishi" style="color:#666">是否复制车辆信息到该维修厂？</div>
    </van-dialog>
  </div>
</template>

<script>
import userwei from "../../api/user";
const delay = (function() {
  let timer = 0;
  return function(callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback.ms);
  };
})();
export default {
  components: {},
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      isok: this.$route.query.isok,
      show: false,
      list: null,
      shenhe: false,
      keyword: "",
      cityList: [],
      timer: null,
      jsonData: []
    };
  },
  watch: {
    keyword() {
      //函数节流
      if (this.timer) {
        clearTimeout(this.timer);
      }
      //删除文字  清零
      if (!this.keyword) {
        this.cityList = [];
        return;
      }
      this.timer = setTimeout(() => {
        const result = [];
        // console.log(this.jsonData);
        this.jsonData.forEach(val => {
          if (val.name != null) {
            if (val.name.indexOf(this.keyword) > -1) {
              result.push(val);
            }
          }
        });
        this.cityList = result;
        // console.log(this.cityList);
      }, 100);
    }
  },
  methods: {
    queding() {
      this.tijiao();
    },
    //取消
    quxiao() {
      this.$toast("添加失败");
      this.shenhe = false;
    },
    onLoad() {
      var data = {
        appId: sessionStorage.getItem('cappid'),
        page: 0,
        size: 300
      };
      userwei
        .garagelists(data)
        .then(e => {
          // loading.clear();
          this.jsonData = e.data;
          // console.log(this.jsonData)
        })
        .catch(() => loading.clear());
    },
    handleCityClick(e) {
      console.log(e);
      this.list = e;
      this.show = false;
    },
    detparts(idx) {
      // this.keyword = "";
      this.list = null;
    },
    put() {
      this.show = true;
    },
    submitForm() {
      if (!this.list) {
        return this.$toast("请先选择再添加！");
      }
      userwei
        .checkadd({ uid: this.userInfo.id })
        .then(res => {
          console.log(res);
          if (res.success == true) {
            if (this.isok == true) {
              this.shenhe = true;
            } else {
              this.tijiao();
            }
          }
        })
        .catch();
      //  location.replace('/tuoke')
    },

    tijiao() {
      let data = {
        gids: this.list.id,
        uid: this.userInfo.id
        // uid: 1661
      };
      userwei
        .adddefault(data)
        .then(res => {
          console.log(res);
          if (res.code === 200) {
            sessionStorage.setItem("scisok", true);
            this.$toast.success("提交成功");
            setTimeout(() => {
              this.$router.go(-1);
            }, 800);
          } else {
            this.$toast(res.message);
          }
        })
        .catch();
    },
    back() {
      //  console.log('45646')
      this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  }
};
</script>

<style lang="less" scoped>
.views {
  /deep/ div:nth-of-type(2) span {
    color: #1989fa;
  }
}

.sumit {
  width: 100%;
  padding: 10px;
  position: absolute;
  bottom: 0;
}
.van-button {
  width: 100%;
}
.van-row {
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #ebedf0;
}
.ls {
  padding-left: 20px;
}
.rs {
  text-align: right;
  padding-right: 20px;
  color: #ee0a24;
}
.van-search {
  padding: 0;
}
.van-cell {
  width: 90%;
  margin: auto;
}
.shikuan {
  border: 1px solid #ebedf0;
  margin: 0px 5%;
  border-top: none;
  z-index: 99;
  position: absolute;
  top: 0;
  margin-top: 35px;
  width: 90%;
  left: 0;
  background: #fff;
}
.van-search {
  width: 90%;
  margin: auto;
}
.linam {
  padding-left: 10px;
  height: 30px;
  font-size: 13px;
  line-height: 30px;
}
.tishi {
  text-indent: 2em;
  padding: 8px 15px;
  font-weight: 500;
  font-size: 16px;
  color: #333;
}
</style>
